const products = {
  highlight: [
    {
      all: [
        {
          id: 4,
          background:
            "https://oficialrefugio.com/wp-content/uploads/2023/03/1636139846-1024x944.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "oficialrefugio",
          title: "21-metodos-naturais-para-ansiedade",
          value: "57,90",
          parcel: "ou 8x de R$ 8,08",
          link: "https://go.hotmart.com/Y91735343S",
        },
        {
          id: 16,
          background:
            "https://static-media.hotmart.com/oTvrmdJPMWoVkgQQsoVS0-AYKOw=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/3e7f551c-e671-4979-a117-9266faa46461/DomineoTempoCapa10001000.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "BIANCA M. L. SANTOS",
          title: "Domine o Tempo",
          value: "97,00",
          parcel: "ou 7x de R$ 15,50",
          link: "https://go.hotmart.com/F91735246U",
        },
        {
          id: 17,
          background:
            "https://static-media.hotmart.com/5H8mY59BV_pLbHp88e0be8FpM7w=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/8e573e5a-b9c3-4d20-af09-28e524adb50d/CAPA2EBOOKRESPIREEVIVACELULARLIVROETABLET.PNG?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "VANEIR DA CONCEIÇÃO OLIVEIRA",
          title:
            "Respire e Viva: Um guia prático para vencer a depressão e encontrar o caminho para uma vida mais leve",
          value: "50,00",
          parcel: "ou 5x de R$ 10,88",
          link: "https://go.hotmart.com/W91735280T",
        },
        {
          id: 20,
          background:
            "https://static-media.hotmart.com/LHL63ILdaEftt4N5kxyI2-1EWbg=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/df5e5e83-7923-4fb8-b4e6-63f51b9f0366/2.jpeg?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "DENIS OLIVEIRA DE CARVALHO",
          title: "Jornada da liberdade emocional",
          value: "197,00",
          parcel: "ou 12x de R$ 19,66",
          link: "https://go.hotmart.com/B91735252A",
        },
        {
          id: 23,
          background:
            "https://static-media.hotmart.com/hl2Jq644mlc9UydEvlSCgfDm0XI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/a54c2174-ff5c-4531-bfe3-07207385cda2/15VdeoAulas04Avaliaes3Livrosdigitais2Audiobooks.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "André Tomé",
          title: "Método: Desvendando o Complexo da Ansiedade",
          value: "77,00",
          parcel: "ou 11x de R$ 8,27",
          link: "https://go.hotmart.com/R91735332N?dp=1",
        },
        {
          id: 24,
          background:
            "https://static-media.hotmart.com/iGp0xEw4kYlCHzpA0zoewdEFO2g=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/07ea47a5-40b0-4062-b8e2-3290851be989/CAPA3.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Beth Silva",
          title: "NÃO SOFRA EM SILÊNCIO",
          value: "59,90",
          link: "https://go.hotmart.com/V91735143A?dp=1",
        },
        {
          id: 1,
          background:
            "https://static-media.hotmart.com/-gq-Z9XQyFXTq0hvH8nBsT5pcMU=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/4d7362ba-802c-4414-a6ad-91de8df6478b/PostInstagramEntendendoaMentedoClienteMarketingDigitalModernoAzul1.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "By RickHugo",
          title: "Mentalidade de Crescimento: A chave para o sucesso.",
          value: "12,99",
          link: "https://go.hotmart.com/R91734872S?dp=1",
        },
        {
          id: 33,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/44/db/44dbe73bfcc84d1cb0d94cdc9dee18e3",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Livraria OFICIAL",
          title: "Os Códigos Do Mindset Da Prosperidade - Pablo Marçal",
          value: "98,00",
          parcel: "ou 12x de R$ 9,80",
          link: "https://chk.eduzz.com/1274742?a=72352725",
        },
        {
          id: 34,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/b8/e1/b8e107e81c08480897f149340d9c8e76",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Juliana",
          title:
            "Livro Físico: Educação Emocional Positiva - O Olhar de quem Faz - Frete Grátis",
          value: "54,40",
          parcel: "ou 12x de R$ 5,44",
          link: "https://chk.eduzz.com/679003?a=72352725",
        },
        {
          id: 35,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/59/65/5965417e50fb4910af380af6d73366bb",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Livraria OFICIAL",
          title: "O milagre da manhã",
          value: "69,00",
          parcel: "ou 12x de R$ 6,90",
          link: "https://sun.eduzz.com/1316205?a=72352725",
        },
        {
          id: 47,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/91/10/91100e20ee7f4c4b95404b8c7b9372cd",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Hélio Redondo",
          title: "E-book Sistema Nervoso Central e as Doenças Neurológicas",
          value: "47,00",
          parcel: "ou 9x de R$ 5,22",
          link: "https://chk.eduzz.com/1817204?a=72352725",
        },
        {
          id: 49,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/8c/bd/8cbde317739b4017b96480fe4d6bfad2",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "Psicologia Negra e Manipulação: 2 em 1",
          value: "99,99",
          parcel: "ou 12x de R$ 10,00",
          link: "https://chk.eduzz.com/2197175?a=72352725",
        },
        {
          id: 52,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/79/bf/79bf70c15d8b4910a6f193b15d69d1b5",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "Controle mental 4 livros em 1",
          value: "80,00",
          parcel: "ou 12x de R$ 8,00",
          link: "https://chk.eduzz.com/1958900?a=72352725",
        },
        {
          id: 56,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/16/0a/160aa78f69184561be663d8a84c8fe62",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jussara Márcia vieira Flois",
          title: "QUERO DIVÓRCIO - ME SAPARANDO DA ANSIEDADE",
          value: "147,00",
          parcel: "ou 12x de R$ 14,70",
          link: "https://chk.eduzz.com/2044553?a=72352725",
        },
        {
          id: 78,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/LrbAPvhAunOQQ1K/Design-sem-nome-5_8eea1dbe9622472485203eb6c92bc31d.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "psicanalisandose",
          title: "1.200 perguntas para o setting terapêutico",
          value: "97,00",
          parcel: "ou 12x de R$ 9,74",
          link: "https://kiwify.app/gexESfe?afid=W0aiu1R9",
        },
        {
          id: 79,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/19Ggvu7m8XKSK2S/img_builder_414baac6-b196-4130-bd9c-04c6d67a0df1_2ba15d89f04b45adb458e6199eef21d4.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "filtrodamente",
          title: "Manual Autoconhecimento",
          value: "34,90",
          parcel: "ou 7x de R$ 5,60",
          link: "https://kiwify.app/nLSyxTu?afid=Hjxqu6gz",
        },
        {
          id: 82,
          background:
            "https://euagradecendo.com.br/wp-content/uploads/2022/10/Manual-da-Gratidao-2.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "euagradecendo",
          title: "MANUAL DA GRATIDÃO + 12 Bônus",
          value: "47,90",
          parcel: "ou 9x de R$ 6,15",
          link: "https://kiwify.app/vYKlIyK?afid=ZrLZA6b9",
        },
        {
          id: 83,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/bSEKGEjTNSCP49A/EBOOK---ANSIEDADE-x-DEUS_8b4f4867c326482284d75fc2a2441999.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "Júnior Araujo",
          title: "Ansiedade x Deus",
          value: "19,90",
          parcel: "ou 4x de R$ 5,35",
          link: "https://pay.kiwify.com.br/Yvp5CiR?afid=QZlgvBbg",
        },
        {
          id: 88,
          background:
            "https://lp.charleshspurgeon.com.br/cura/wp-content/uploads/2023/05/Curadalma-cover.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "charleshspurgeon",
          title: "Cural da Alma",
          value: "35,00",
          parcel: "ou 7x de R$ 5,62",
          link: "https://kiwify.app/1e7gOSP?afid=3hKdNZKp",
        },
        {
          id: 89,
          background:
            "https://reconquisteem7dias.site/wp-content/uploads/2024/01/1705793130-768x589.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "reconquisteem7dias",
          title: "O Segredo da Reconquista",
          value: "47,00",
          parcel: "ou 11x de R$ 5,08",
          link: "https://kiwify.app/LREYDfo?afid=TEONT6XZ",
        },
        {
          id: 91,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/rR74ZddYmp9WGv7/1_5c4ed715f3d44df29b6acc7eedc7370f.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "sociedadepsicanalitica",
          title: "Curso - PSICANÁLISE EXPERIENCE",
          value: "197,00",
          parcel: "ou 12x de R$ 19,78",
          link: "https://kiwify.app/ahUCuk6?afid=dmoiK3xG",
        },
      ],
    },
  ],
  list: [
    {
      all: [
        {
          id: 1,
          background:
            "https://static-media.hotmart.com/-gq-Z9XQyFXTq0hvH8nBsT5pcMU=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/4d7362ba-802c-4414-a6ad-91de8df6478b/PostInstagramEntendendoaMentedoClienteMarketingDigitalModernoAzul1.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "By RickHugo",
          title: "Mentalidade de Crescimento: A chave para o sucesso.",
          value: "12,99",
          link: "https://go.hotmart.com/R91734872S?dp=1",
        },
        {
          id: 2,
          background:
            "https://static-media.hotmart.com/qV0b6rv9DvzmDqfWYXkc3cI6GFM=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/5dc059ae-64ed-4526-af37-f73f83a8f030/AdrianaReiscopiar.jpg?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Adriana Reis",
          title: "Superação através da gratidão",
          value: "11,90",
          link: "https://go.hotmart.com/Y91734980M?dp=1",
        },
        {
          id: 3,
          background:
            "https://static-media.hotmart.com/nzG05zt0momLle44e8UajqQTt5U=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/fa9a97b0-d125-44aa-8051-db02f240acb6/ebooks.jpg?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "WANDERSON ADRIANO OLIVEIRA SILVA",
          title: "115 Livros digitais pelo preço de 1",
          value: "16,90",
          parcel: "ou 2x de R$ 8,82",
          link: "https://go.hotmart.com/J91735265Q",
        },
        {
          id: 4,
          background:
            "https://oficialrefugio.com/wp-content/uploads/2023/03/1636139846-1024x944.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "oficialrefugio",
          title: "21-metodos-naturais-para-ansiedade",
          value: "57,90",
          parcel: "ou 8x de R$ 8,08",
          link: "https://go.hotmart.com/Y91735343S",
        },
        {
          id: 5,
          background:
            "https://static-media.hotmart.com/LlZr5YBgcCnYnqKgZim_u8xshAo=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/ac1ccc3a-358a-4c74-9680-ec34bed31f32/CAPAPNGPARAHOTMARTFINAL.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Cleiciane Vieira",
          title: "30 DIAS PARA SE LIBERTAR DE UM NARCISISTA PERVERSO",
          value: "34,90",
          parcel: "ou 4x de R$ 9,36",
          link: "https://go.hotmart.com/F91735230K",
        },
        {
          id: 7,
          background:
            "https://static-media.hotmart.com/kz7NBemPBrT_I_lrBW-RWyYz2pc=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/26ffab42-cc99-4f57-ade4-4039675fcc54/artrenovamente.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Dione Nascimento",
          title: "7 Dias Para Renovar Sua Mente",
          value: "14,99",
          link: "https://go.hotmart.com/Q91735277F",
        },
        {
          id: 8,
          background:
            "https://static-media.hotmart.com/ImleC3aqVuVdMaUZVXQgWnuueS0=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/79c72344-4bab-4c37-b853-e2e6a8eff6ae/bloqueiosemocionaisqueteimpedemdeavancar.jpg?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Fabio Tanida",
          title: "Bloqueios Emocionais que te impedem de avançar!",
          value: "37,99",
          link: "https://go.hotmart.com/E91735227L",
        },
        {
          id: 9,
          background:
            "https://static-media.hotmart.com/-mnos4wyA5lsO8DbuXJTTXkxJWs=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/f470f5a5-6f06-4bed-9402-361b09c0c3da/CAPACOLETANEAINTELIGENCIAEMOCIONALNAESCOLA.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Nova Era Recursos Terapêuticos",
          title: "Combo Inteligência Emocional na Escola",
          value: "29,90",
          parcel: "ou 4x de R$ 7,48",
          link: "https://go.hotmart.com/A91735259R",
        },
        {
          id: 10,
          background:
            "https://static-media.hotmart.com/v9k8oPa5FuWgPvEvnvS1mLvfgig=/fit-in/400x400/filters:quality(100):format(webp)/klickart-prod/uploads/media/file/7398393/como_superar_ansiedade_infoproduto_plr_ebook_vothhz.jpg",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "jonatasaugusto3",
          title: "Supere a Ansiedade: Aprenda a Controlar Suas Emoções",
          value: "39,90",
          link: "https://go.hotmart.com/T91735292C",
        },
        {
          id: 11,
          background:
            "https://static-media.hotmart.com/EemIM5rYgCvNeFiTH1OOAZnvyog=/filters:quality(100)/klickart-prod/uploads/media/file/7503943/da_ansiedade_para_a_luz.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Cristiano Moraes",
          title: "Da Ansiedade Para A Luz",
          value: "45,90",
          parcel: "ou 6x de R$ 8,44",
          link: "https://go.hotmart.com/K91735322M",
        },
        {
          id: 12,
          background: "../../assets/Captura de tela 2024-03-17 180727.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "ecormerce digital",
          title: "Depressão na adolescência",
          value: "29,90",
          parcel: "ou 3x de R$ 10,55",
          link: "https://go.hotmart.com/D91735295P",
        },
        {
          id: 13,
          background:
            "https://static-media.hotmart.com/Ig9ZQoClFCZnomgyP0g4YsAVRN8=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/bc2dfc27-148c-4d8c-82fa-db9e8fb6544b/CopiadeASESORIAFINANCIERAYLEGAL31.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Guilherme Araújo",
          title: "Derrotando a ansiedade - Ebook Completo",
          value: "29,99",
          parcel: "ou 4x de R$ 8,05",
          link: "https://go.hotmart.com/R91735286F",
        },
        {
          id: 14,
          background:
            "https://static-media.hotmart.com/JWPSeTJfmmuytvDXbPpQy4I92w0=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/235bbbe8-acd1-46a6-b038-9ebf388c3e6e/DesperteseuPotencialCognitivo.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Daniel",
          title:
            "Desperte seu Potencial Cognitivo: Dicas e Técnicas para Melhorar sua Memória",
          value: "29,90",
          link: "https://go.hotmart.com/F91735235L",
        },
        {
          id: 15,
          background:
            "https://static-media.hotmart.com/9f62HyKg3Bj8meAl3nVq4xiY8g0=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/462706c6-ede8-49e5-8053-6225ed9c9601/DesvendandoRelaesem07PassosDaianeTondatto.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "DAMA TEORIA",
          title: "DESVENDANDO RELAÇÕES ABUSIVAS EM SETE PASSOS",
          value: "19,90",
          link: "https://go.hotmart.com/K91735206T",
        },
        {
          id: 16,
          background:
            "https://static-media.hotmart.com/oTvrmdJPMWoVkgQQsoVS0-AYKOw=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/3e7f551c-e671-4979-a117-9266faa46461/DomineoTempoCapa10001000.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "BIANCA M. L. SANTOS",
          title: "Domine o Tempo",
          value: "97,00",
          parcel: "ou 7x de R$ 15,50",
          link: "https://go.hotmart.com/F91735246U",
        },
        {
          id: 17,
          background:
            "https://static-media.hotmart.com/5H8mY59BV_pLbHp88e0be8FpM7w=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/8e573e5a-b9c3-4d20-af09-28e524adb50d/CAPA2EBOOKRESPIREEVIVACELULARLIVROETABLET.PNG?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "VANEIR DA CONCEIÇÃO OLIVEIRA",
          title:
            "Respire e Viva: Um guia prático para vencer a depressão e encontrar o caminho para uma vida mais leve",
          value: "50,00",
          parcel: "ou 5x de R$ 10,88",
          link: "https://go.hotmart.com/W91735280T",
        },
        {
          id: 18,
          background:
            "https://autoestimaindestrutivel.com/wp-content/uploads/elementor/thumbs/download-28573671631406606-pczcolm6yit35k69676vlsj46g5yg4q0aicuqrp2sw.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Maurício Martins Nunes",
          title: "Em primeiro lugar, aprenda a se amar",
          value: "49,90",
          parcel: "ou 6x de R$ 9,18",
          link: "https://go.hotmart.com/X91735335K",
        },
        {
          id: 19,
          background:
            "https://neuroativamente.com.br/wp-content/uploads/2023/07/R.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Marcos Costa",
          title: "Estimulação Cognitiva da atenção",
          value: "27,00",
          parcel: "ou 3x de R$ 9,53",
          link: "https://go.hotmart.com/X91735338E",
        },
        {
          id: 20,
          background:
            "https://static-media.hotmart.com/LHL63ILdaEftt4N5kxyI2-1EWbg=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/df5e5e83-7923-4fb8-b4e6-63f51b9f0366/2.jpeg?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "DENIS OLIVEIRA DE CARVALHO",
          title: "Jornada da liberdade emocional",
          value: "197,00",
          parcel: "ou 12x de R$ 19,66",
          link: "https://go.hotmart.com/B91735252A",
        },
        {
          id: 21,
          background:
            "https://static-media.hotmart.com/ZjptMtZXIOJ_v7zrTausZY0GjMQ=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/5d7399bd-e6fc-464f-8b0c-a80be7ccc393/CAPALIBERTACAO.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Instituto Performa",
          title: "Libertação | O Fim Das Feridas Emocionais",
          value: "47,00",
          parcel: "ou 6x de R$ 8,64",
          link: "https://go.hotmart.com/O91735203V",
        },
        {
          id: 22,
          background:
            "https://static-media.hotmart.com/JHn-TrJgSSCEwOfbuG4KXsInhro=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/c82a4a9a-4f0a-4b16-9b00-028e98e3eef2/capa3D.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Luiz Henrique Godinho de Souza",
          title: "Linguagem Corporal",
          value: "23,50",
          parcel: "ou 3x de R$ 8,29",
          link: "https://go.hotmart.com/S91735267B",
        },
        {
          id: 23,
          background:
            "https://static-media.hotmart.com/hl2Jq644mlc9UydEvlSCgfDm0XI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/a54c2174-ff5c-4531-bfe3-07207385cda2/15VdeoAulas04Avaliaes3Livrosdigitais2Audiobooks.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "André Tomé",
          title: "Método: Desvendando o Complexo da Ansiedade",
          value: "77,00",
          parcel: "ou 11x de R$ 8,27",
          link: "https://go.hotmart.com/R91735332N?dp=1",
        },
        {
          id: 24,
          background:
            "https://static-media.hotmart.com/iGp0xEw4kYlCHzpA0zoewdEFO2g=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/07ea47a5-40b0-4062-b8e2-3290851be989/CAPA3.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Beth Silva",
          title: "NÃO SOFRA EM SILÊNCIO",
          value: "59,90",
          link: "https://go.hotmart.com/V91735143A?dp=1",
        },
        {
          id: 25,
          background:
            "https://static-media.hotmart.com/iW_STqn2moaP6kL9UlUmdkLuZRc=/1024x1641/filters:quality(100)/hotmart/checkout_custom/b33998b7-92c0-4c7f-a820-e7f59c9a0ad7/axnp2m4b.png",
          title: "Pack = 9 livros (todos os livros de caiocesar220)",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "caiocesar220",
          value: "59,90",
          parcel: "ou 8x de R$ 8,49",
          link: "https://go.hotmart.com/H91735364W",
        },
        {
          id: 26,
          background:
            "https://static-media.hotmart.com/1BzvTQtmMZnzaOW7LliSNXYtq6E=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/bdc5636a-1945-4fff-8d0b-673039e3dbc6/capadapagina2.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Edt Ramos",
          title: "PROTOCOLO ZERO ANSIEDADE",
          value: "29,90",
          parcel: "ou 3x de R$ 10,55",
          link: "https://go.hotmart.com/E91735305L?dp=1",
        },
        {
          id: 27,
          background:
            "https://static-media.hotmart.com/a7u8d10jnkjbh0syHo6FPit0ar4=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/17d1d0a6-72ce-461e-a50d-bf8b7fcc332a/_20230519_135619_0000.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Silvio de Figueiredo Quinzano",
          title: "Sobre / Vivendo ao TDAH",
          value: "10,00",
          link: "https://go.hotmart.com/S91735241W",
        },
        {
          id: 28,
          background:
            "https://static-media.hotmart.com/pKIFd967hqX1T0mdzSIBnBVlG7E=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/98d6cd43-6ea4-4781-92bf-3df45d83b0f3/Designsemnome.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Sobreautoestima",
          title: "Sobre Autoestima e Amor Próprio",
          value: "29,90",
          parcel: "ou 3x de R$ 10,55",
          link: "https://hotmart.com/pt-br/marketplace/produtos/sobreautoestimaeamorproprio/O44570921M?ref=F91735347E",
        },
        {
          id: 29,
          background:
            "https://static-media.hotmart.com/-wNlOtbuSWOgt9Jhj74IuGEQnT4=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/6034b7a8-d2ea-49f0-9790-e388e31b2061/SuaMenteMaisSaudavel.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Alexsandro Schmidt",
          title: "Sua Mente Mais Saudável",
          value: "45,00",
          parcel: "ou 6x de R$ 8,28",
          link: "https://go.hotmart.com/G91735217V",
        },
        {
          id: 30,
          background:
            "https://static-media.hotmart.com/dEuky64hRFG8x43qyvBB-tA_4WY=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/8f8826a7-6de1-4b1b-9a93-07d58b8b4c26/feedebooktecnicas.png?w=920",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Reinaldo Araujo Júnior",
          title: "Técnicas e Hábitos Para Controlar a Ansiedade",
          value: "9,90",
          link: "https://go.hotmart.com/S91735301A",
        },
        {
          id: 31,
          background:
            "https://manualcontra-ansiedade.my.canva.site/ansiedade-ajuda-tratamento/images/15379934c0d41f95b6f7c31ad2e49a21.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "João Pedro",
          title: "Vencendo a ansiedade",
          value: "29,90",
          parcel: "ou 3x de R$ 10,55",
          link: "https://go.hotmart.com/I91735273O",
        },
        {
          id: 32,
          background: "../../assets/Captura de tela 2024-03-17 183652.png",
          logo: "../../assets/Hotmart-logo-02.svg",
          user: "Núcleo mental",
          title: "Os códigos do sono e Vencendo as barreiras da ansiedade",
          value: "37,90",
          parcel: "ou 5x de R$ 8,25",
          link: "https://go.hotmart.com/P91735302J",
        },
        {
          id: 33,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/44/db/44dbe73bfcc84d1cb0d94cdc9dee18e3",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Livraria OFICIAL",
          title: "Os Códigos Do Mindset Da Prosperidade - Pablo Marçal",
          value: "98,00",
          parcel: "ou 12x de R$ 9,80",
          link: "https://chk.eduzz.com/1274742?a=72352725",
        },
        {
          id: 34,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/b8/e1/b8e107e81c08480897f149340d9c8e76",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Juliana",
          title:
            "Livro Físico: Educação Emocional Positiva - O Olhar de quem Faz - Frete Grátis",
          value: "54,40",
          parcel: "ou 12x de R$ 5,44",
          link: "https://chk.eduzz.com/679003?a=72352725",
        },
        {
          id: 35,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/59/65/5965417e50fb4910af380af6d73366bb",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Livraria OFICIAL",
          title: "O milagre da manhã",
          value: "69,00",
          parcel: "ou 12x de R$ 6,90",
          link: "https://sun.eduzz.com/1316205?a=72352725",
        },
        {
          id: 36,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/b8/a1/b8a15ca9ea9c4f589ba79d803dc4c781",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Augusto Cury",
          title: "A Fascinante construção do eu",
          value: "10,00",
          parcel: "ou 2x de R$ 5,00",
          link: "https://chk.eduzz.com/2200582?a=72352725",
        },
        {
          id: 37,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/aa/0b/aa0b5e7a7cbe403c9827f5c9dffec7a4",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Evelen Silva",
          title: "Derrotando a insônia",
          value: "9,99",
          parcel: "ou 2x de R$ 5,22",
          link: "https://chk.eduzz.com/2227906?a=72352725",
        },
        {
          id: 38,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/b6/69/b669697cbbda4ba4a42d3d05b15065be",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Gisele Martins",
          title: "Método Mulher Próspera",
          value: "19,90",
          parcel: "ou 4x de R$ 5,34",
          link: "https://chk.eduzz.com/2017485?a=72352725",
        },
        {
          id: 39,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/84/80/84809d55a09740d29d054987f9b066a4",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Josue brito pinheiro sousa",
          title: "O Poder da ação",
          value: "19,90",
          parcel: "ou 4x de R$ 5,39",
          link: "https://chk.eduzz.com/2112889?a=72352725",
        },
        {
          id: 40,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/df/67/df6726ac0a0144a495bc97534f255cc3",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "",
          title: "27 dias para aumentar seu amor próprio",
          value: "19,90",
          parcel: "ou 4x de R$ 5,34",
          link: "https://chk.eduzz.com/733022?a=72352725",
        },
        {
          id: 41,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/19/30/1930ff3ad26348aeb910d9c1b535dfe4",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Priscila Roel",
          title: "ConscienteMente - Mude seu mundo, Destrave sua Mente",
          value: "21,90",
          parcel: "ou 4x de R$ 5,88",
          link: "https://chk.eduzz.com/737664?a=72352725",
        },
        {
          id: 42,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/4f/1c/4f1c90af6f3a46ae838f2eadd6fed7cc",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Douglas Henrique de Oliveira",
          title: "As 5 Faces da Dependência Emocional - 1 edição.",
          value: "14,90",
          parcel: "ou 3x de R$ 5,26",
          link: "https://chk.eduzz.com/1579345?a=72352725",
        },
        {
          id: 43,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/2b/fe/2bfeb14735bd4ab9b9653bc1db103091",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "Como Reprogramar sua mente para ter sucesso",
          value: "20,00",
          parcel: "ou 4x de R$ 5,37",
          link: "https://chk.eduzz.com/2094803?a=72352725",
        },
        {
          id: 44,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/47/8a/478a19e0c8eb4e36bc461a81dead441e",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Cássia Rodrigues",
          title:
            "Entendendo e Superando a Dependência Emocional: Torne-se o Arquiteto da Sua Felicidade",
          value: "22,99",
          parcel: "ou 5x de R$ 5,06",
          link: "https://chk.eduzz.com/2143147?a=72352725",
        },
        {
          id: 45,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/9b/59/9b59e4109d8b4953b09580894a213770",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Adriana Jaqueline Vipievski",
          title:
            "E-book Despertando sua força interior - Desenvolva sua Autoestima como mulher.",
          value: "19,99",
          parcel: "ou 4x de R$ 5,37",
          link: "https://chk.eduzz.com/2003836?a=72352725",
        },
        {
          id: 46,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/f8/b5/f8b53051e178493e97ecb2cf8c007acf",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Ana. C. BULBOW",
          title: "Crise de ansiedade. RELATO, DICAS E AJUDA!",
          value: "45,00",
          parcel: "ou 10x de R$ 5,25",
          link: "https://chk.eduzz.com/2044355?a=72352725",
        },
        {
          id: 47,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/91/10/91100e20ee7f4c4b95404b8c7b9372cd",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Hélio Redondo",
          title: "E-book Sistema Nervoso Central e as Doenças Neurológicas",
          value: "47,00",
          parcel: "ou 9x de R$ 5,22",
          link: "https://chk.eduzz.com/1817204?a=72352725",
        },
        {
          id: 48,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/f6/35/f6356dc4e00346ad9398686ccaad8cb0",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Wilman Mello",
          title: "Superando Desafios: 10 E-books para Transformar a Sua Vida",
          value: "29,90",
          parcel: "ou 6x de R$ 5,57",
          link: "https://chk.eduzz.com/2116642?a=72352725",
        },
        {
          id: 49,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/8c/bd/8cbde317739b4017b96480fe4d6bfad2",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "Psicologia Negra e Manipulação: 2 em 1",
          value: "99,99",
          parcel: "ou 12x de R$ 10,00",
          link: "https://chk.eduzz.com/2197175?a=72352725",
        },
        {
          id: 50,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/2d/d2/2dd23bd99d66411b99d44a325ad67693",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Lolrian Produtos Digitais",
          title: "A Felicidade Começa com Você - E-book",
          value: "19,99",
          parcel: "ou 4x de R$ 5,00",
          link: "https://chk.eduzz.com/1124704?a=72352725",
        },
        {
          id: 51,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/a6/20/a6209eb0c9bc4425bb23187b45136788",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "James C. Hunter",
          title: "O Monge e o Executivo",
          value: "19,90",
          parcel: "ou 3x de R$ 6,63",
          link: "https://chk.eduzz.com/42511?a=72352725",
        },
        {
          id: 52,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/79/bf/79bf70c15d8b4910a6f193b15d69d1b5",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "Controle mental 4 livros em 1",
          value: "80,00",
          parcel: "ou 12x de R$ 8,00",
          link: "https://chk.eduzz.com/1958900?a=72352725",
        },
        {
          id: 53,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/84/20/84200facd0d64d699e78e07403bf5fe2",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Felipe F. Oliveira",
          title: "COMO VENCER AS TRISTEZAS - O Livro",
          value: "27,00",
          parcel: "ou 5x de R$ 5,88",
          link: "https://chk.eduzz.com/1870412?a=72352725",
        },
        {
          id: 54,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/97/7f/977f64b00b0b4c7587bdb9b60b35170c",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Vitor Silva e Karine Flores",
          title:
            "Como superar a ansiedade no trabalho: um guia prático para encontrar equilíbrio e bem-estar",
          value: "67,00",
          parcel: "ou 12x de R$ 6,70",
          link: "https://chk.eduzz.com/1891175?a=72352725",
        },
        {
          id: 55,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/a6/d0/a6d078622abb41ad80bfe887bb170e24",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Saves.time",
          title: "QUEM- PENSA ENRIQUECE -[Think and Grow Rich]",
          value: "9,90",
          parcel: "ou 2x de R$ 5,17",
          link: "https://chk.eduzz.com/1407937?a=72352725",
        },
        {
          id: 56,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/16/0a/160aa78f69184561be663d8a84c8fe62",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jussara Márcia vieira Flois",
          title: "QUERO DIVÓRCIO - ME SAPARANDO DA ANSIEDADE",
          value: "147,00",
          parcel: "ou 12x de R$ 14,70",
          link: "https://chk.eduzz.com/2044553?a=72352725",
        },
        {
          id: 57,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/c1/64/c1643249a8bb4f2385dc451364f4d29c",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "eduzzmilgrau",
          title: "EMPATIA PSÍQUICA 5 LIVROS EM 1",
          value: "50,00",
          parcel: "ou 12x de R$ 5,00",
          link: "https://chk.eduzz.com/2235242?a=72352725",
        },
        {
          id: 58,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/ad/f3/adf33d697b5e453bbecdd8c10fa28928",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Jideon F. Marques",
          title: "101 maneira de acalmar a ansiedade e parar o pânico",
          value: "20,00",
          parcel: "ou 4x de R$ 5,37",
          link: "https://chk.eduzz.com/2093810?a=72352725",
        },
        {
          id: 59,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/6e/bf/6ebfd0c23c1c42c38b5b95549e31626c",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "kaiky baiao",
          title: "Sete táticas para reconquistar o controle da sua vida",
          value: "47,00",
          parcel: "ou 9x de R$ 5,22",
          link: "https://chk.eduzz.com/1980336?a=72352725",
        },
        {
          id: 60,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/b3/c3/b3c3c3d70e894e1aad0a419f912b8288",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Letícia",
          title:
            "Livro - Mais esperto que o diabo - O mistério revelado da liberdade e do sucesso",
          value: "10,00",
          parcel: "ou 2x de R$ 5,00",
          link: "https://chk.eduzz.com/1904082?a=72352725",
        },
        {
          id: 61,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/08/6d/086d98398a014882b060785729c560aa",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Fernanda Junho e Décio Silveira - Yoga na Fonte",
          title: "Aprendendo a Respirar",
          value: "20,00",
          link: "https://chk.eduzz.com/1536809?a=72352725",
        },
        {
          id: 62,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/10/20/10206bdc04994415ba7b0dcd9defe1cd",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Terapeuta Jorgenete Silva",
          title:
            "E-Book - Aprenda técnicas para controlar altos niveis de Ansiedade",
          value: "27,00",
          parcel: "ou 5x de R$ 5,88",
          link: "https://chk.eduzz.com/2047128?a=72352725",
        },
        {
          id: 63,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/cf/85/cf8537c8629344da9c3e46f3fb2f491f",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Adryano",
          title: "Manual da mente",
          value: "17,90",
          parcel: "ou 3x de R$ 6,32",
          link: "https://chk.eduzz.com/2223491?a=72352725",
        },
        {
          id: 64,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/a6/53/a653cce1c95743f8a104b8c2cee01df3",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Renata Geist",
          title: "5 Maneiras Fáceis de Impulsionar sua Inteligência Emocional",
          value: "9,99",
          parcel: "ou 2x de R$ 5,00",
          link: "https://chk.eduzz.com/2222408?a=72352725",
        },
        {
          id: 65,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/25/da/25da3cac04ca48098a980ac4d055c816",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Alan Silva",
          title: "10 segredos para vencer a baixa autoestima",
          value: "9,90",
          parcel: "ou 2x de R$ 5,17",
          link: "https://chk.eduzz.com/1797842?a=72352725",
        },
        {
          id: 66,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/2b/d5/2bd5ce41db0940b0beeeff6931b49f01",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Oziane Santos brito",
          title: "Desperte o seu PODER interior",
          value: "14,90",
          parcel: "ou 3x de R$ 5,30",
          link: "https://chk.eduzz.com/2257699?a=72352725",
        },
        {
          id: 67,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/50/f3/50f31accaaf84dd98bd772fec2263130",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Mari Zanella",
          title: "Meditação para Iniciantes",
          value: "27,00",
          parcel: "ou 5x de R$ 5,88",
          link: "https://chk.eduzz.com/1988641?a=72352725",
        },
        {
          id: 68,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/8d/51/8d516e1490404287b0901de767805145",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Rízia Santos",
          title: "Técnicas Infalíveis para melhorar a concentração no trabalho",
          value: "20,00",
          parcel: "ou 4x de R$ 5,37",
          link: "https://chk.eduzz.com/1970385?a=72352725",
        },
        {
          id: 69,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/de/87/de87b9af12094890a067fdd46651585d",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Lety Assis",
          title: "SOS Mulheres que Amam Demais",
          value: "14,90",
          parcel: "ou 3x de R$ 5,26",
          link: "https://chk.eduzz.com/776738?a=72352725",
        },
        {
          id: 70,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/2a/63/2a6376848f3646aa8e045f43f49ce736",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Carem Tatiane Dunck",
          title: "A felicidade começa com você",
          value: "37,90",
          parcel: "ou 8x de R$ 5,38",
          link: "https://chk.eduzz.com/1873722?a=72352725",
        },
        {
          id: 71,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/32/48/3248dceaac684b96bb1550d1e396f57b",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Camila Dos Santos Pereira",
          title: "SEGREDOS PARA HACKEAR A MENTE",
          value: "39,90",
          parcel: "ou 9x de R$ 5,11",
          link: "https://chk.eduzz.com/1939521?a=72352725",
        },
        {
          id: 72,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/32/00/3200134ef93d4193b17b77695c34d966",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Letícia",
          title: "Livro - O poder da ação | Paulo Vieira",
          value: "15,00",
          parcel: "ou 3x de R$ 5,00",
          link: "https://chk.eduzz.com/1890741?a=72352725",
        },
        {
          id: 73,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/ab/24/ab240f4d396d4399b058bc9ae203e0a6",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Julianne",
          title: "Reiventando-se: a arte de se desenvolver e crescer",
          value: "19,90",
          parcel: "ou 4x de R$ 5,34",
          link: "https://chk.eduzz.com/1878964?a=72352725",
        },
        {
          id: 74,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/25/c7/25c7d21b18dc44ccb45062bbbfb51e9c",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Agatha Lee Harper",
          title: "E-book O sucesso começa dentro de você",
          value: "24,99",
          parcel: "ou 5x de R$ 5,44",
          link: "https://chk.eduzz.com/1684204?a=72352725",
        },
        {
          id: 75,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/98/a3/98a3f2dea9a643a483f98e6cbdceedc5",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Leonardo Cabral",
          title: "SUPERE A ANSIEDADE",
          value: "29,90",
          parcel: "ou 6x de R$ 5,51",
          link: "https://chk.eduzz.com/2062605?a=72352725",
        },
        {
          id: 76,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/63/b2/63b2f2e8d9604cd2b64074f5a220e851",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Erick",
          title: "Controlando a ansiedade",
          value: "19,90",
          parcel: "ou 4x de R$ 5,34",
          link: "https://chk.eduzz.com/1836486?a=72352725",
        },
        {
          id: 77,
          background:
            "https://cdn.eduzzcdn.com/myeduzz/upload/2a/ec/2aec445caa3b4544bf16dcb9c5133947",
          logo: "../../assets/eduzz-logo-01.svg",
          user: "Saves.time",
          title: "+ De 200 Livros em Ebook",
          value: "14,90",
          parcel: "ou 2x de R$ 7,45",
          link: "https://chk.eduzz.com/1410744?a=72352725",
        },
        {
          id: 78,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/LrbAPvhAunOQQ1K/Design-sem-nome-5_8eea1dbe9622472485203eb6c92bc31d.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "psicanalisandose",
          title: "1.200 perguntas para o setting terapêutico",
          value: "97,00",
          parcel: "ou 12x de R$ 9,74",
          link: "https://kiwify.app/gexESfe?afid=W0aiu1R9",
        },
        {
          id: 79,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/19Ggvu7m8XKSK2S/img_builder_414baac6-b196-4130-bd9c-04c6d67a0df1_2ba15d89f04b45adb458e6199eef21d4.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "filtrodamente",
          title: "Manual Autoconhecimento",
          value: "34,90",
          parcel: "ou 7x de R$ 5,60",
          link: "https://kiwify.app/nLSyxTu?afid=Hjxqu6gz",
        },
        {
          id: 80,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/FADuCyalKDyG4UP/Link-na-bio_a866121cf69b413d92964f5994a0a4f3.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "pilaresparaofoco",
          title: "Os 6 Pilares Para o Foco + Bônus",
          value: "29,90",
          parcel: "ou 5x de R$ 6,53",
          link: "https://kiwify.app/ahvhZ7q?afid=RKGoS1gA",
        },
        {
          id: 81,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/xOGQn1lLjLQPFKA/Vencendo-a-ansiedade---Livro---1_5adb1bef06114c81a652f3c89da3b987.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "",
          title:
            "Vencendo a ansiedade - um guia prático para recuperar sua paz interior",
          value: "19,90",
          parcel: "ou 4x de R$ 5,38",
          link: "https://pay.kiwify.com.br/XQkdL1y?afid=KXAxoqqU",
        },
        {
          id: 82,
          background:
            "https://euagradecendo.com.br/wp-content/uploads/2022/10/Manual-da-Gratidao-2.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "euagradecendo",
          title: "MANUAL DA GRATIDÃO + 12 Bônus",
          value: "47,90",
          parcel: "ou 9x de R$ 6,15",
          link: "https://kiwify.app/vYKlIyK?afid=ZrLZA6b9",
        },
        {
          id: 83,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/bSEKGEjTNSCP49A/EBOOK---ANSIEDADE-x-DEUS_8b4f4867c326482284d75fc2a2441999.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "Júnior Araujo",
          title: "Ansiedade x Deus",
          value: "19,90",
          parcel: "ou 4x de R$ 5,35",
          link: "https://pay.kiwify.com.br/Yvp5CiR?afid=QZlgvBbg",
        },
        {
          id: 84,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/To9Lk89XUErS2wP/img_builder_b2b6089f-ac3d-45c0-829b-0f53d3265159_b80960ab511b41bd85c12c2012dfc93b.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "",
          title: "Hackeando a Ansiedade",
          value: "57,90",
          parcel: "ou 5x de R$ 12,64",
          link: "https://pay.kiwify.com.br/eRn3r8j?afid=88QP6EXv",
        },
        {
          id: 85,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/Af13pgGLUzkDWUR/capa-3d-livros-ebooks-novo_5ad50afcae984f0089a9b8aacb2271ac.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "BIBLIOTECA EXPERT",
          title: "Mais de 1300 livros digitais + bônus de 200 áudios books",
          value: "47,90",
          parcel: "ou 11x de R$ 5,17",
          link: "https://pay.kiwify.com.br/qL5Galu?afid=zTT8JXdt",
        },
        {
          id: 86,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=1000/pnBmGOCt7tNEyit/img_builder_9b272828-b026-4f4f-af92-5bb50fc142f2_eb5e95eb959946239f1ff637ec3a6edf.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "",
          title: "Desafio Você Irreconhecível",
          value: "197,00",
          parcel: "ou 10x de R$ 23,08",
          link: "https://pay.kiwify.com.br/XE8TnJa?afid=Oqu2ntZf",
        },
        {
          id: 87,
          background:
            "https://aws-assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/RppWILWI03rPvyA/MANUAL-CAPA_b93866e771b247a087b30d08f38bc6a4.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "",
          title: "MANUAL DA MULHER SÁBIA",
          value: "19,90",
          parcel: "ou 3x de R$ 7,03",
          link: "https://pay.kiwify.com.br/toTZHNc?afid=OLsrEpDQ",
        },
        {
          id: 88,
          background:
            "https://lp.charleshspurgeon.com.br/cura/wp-content/uploads/2023/05/Curadalma-cover.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "charleshspurgeon",
          title: "Cural da Alma",
          value: "35,00",
          parcel: "ou 7x de R$ 5,62",
          link: "https://kiwify.app/1e7gOSP?afid=3hKdNZKp",
        },
        {
          id: 89,
          background:
            "https://reconquisteem7dias.site/wp-content/uploads/2024/01/1705793130-768x589.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "reconquisteem7dias",
          title: "O Segredo da Reconquista",
          value: "47,00",
          parcel: "ou 11x de R$ 5,08",
          link: "https://kiwify.app/LREYDfo?afid=TEONT6XZ",
        },
        {
          id: 90,
          background:
            "https://static-media.hotmart.com/5056OFMAkYp-pBDhXnNV9YWhh2A=/fit-in/700x400/filters:quality(100)/klickart-prod/uploads/media/file/6631180/smartmockups_ll2vdf2c.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "",
          title: "MÉTODO CAVALEIRO BRANCO",
          value: "19,90",
          parcel: "ou 9x de R$ 5,12",
          link: "https://kiwify.app/ycEE84U?afid=TlFcWIeU",
        },
        {
          id: 91,
          background:
            "https://assets.kiwify.com.br/cdn-cgi/image/fit=scale-down,width=128/rR74ZddYmp9WGv7/1_5c4ed715f3d44df29b6acc7eedc7370f.png",
          logo: "../../assets/kiwify-logo-03.svg",
          user: "sociedadepsicanalitica",
          title: "Curso - PSICANÁLISE EXPERIENCE",
          value: "197,00",
          parcel: "ou 12x de R$ 19,78",
          link: "https://kiwify.app/ahUCuk6?afid=dmoiK3xG",
        },
      ],
    },
  ],
};

export default products;
