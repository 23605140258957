import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionAllProducts from "../../components/Products/components/SectionAllProducts";
import About from "../../components/About";

const Products = () => {
  return (
    <>
      <Header />
      <About />
      <SectionAllProducts />
      <Footer />
    </>
  );
};

export default Products;
