import { Container } from "react-bootstrap";

export default function About() {
  return (
    <Container fluid className="about px-4 px-md-5 px-lg-5 pt-lg-5 mt-0">
      <span>Saúde mental e digital</span>
      <h1 className="fw-bold">
        Negócios
        <br />& marketing digital
      </h1>
      <p>
        Descubra as melhores oportunidades e desfrute do mundo digital, tudo
        reunido em um único lugar.
      </p>

      <a
        href="#produtos"
        className="p-2 px-3 rounded-5 d-inline-flex align-items-center gap-5"
      >
        Nossos Produtos
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM4.5 7.5C4.36739 7.5 4.24021 7.55268 4.14645 7.64645C4.05268 7.74021 4 7.86739 4 8C4 8.13261 4.05268 8.25979 4.14645 8.35355C4.24021 8.44732 4.36739 8.5 4.5 8.5H10.293L8.146 10.646C8.09951 10.6925 8.06264 10.7477 8.03748 10.8084C8.01232 10.8692 7.99937 10.9343 7.99937 11C7.99937 11.0657 8.01232 11.1308 8.03748 11.1916C8.06264 11.2523 8.09951 11.3075 8.146 11.354C8.19249 11.4005 8.24768 11.4374 8.30842 11.4625C8.36916 11.4877 8.43426 11.5006 8.5 11.5006C8.56574 11.5006 8.63084 11.4877 8.69158 11.4625C8.75232 11.4374 8.80751 11.4005 8.854 11.354L11.854 8.354C11.9006 8.30755 11.9375 8.25238 11.9627 8.19163C11.9879 8.13089 12.0009 8.06577 12.0009 8C12.0009 7.93423 11.9879 7.86911 11.9627 7.80837C11.9375 7.74762 11.9006 7.69245 11.854 7.646L8.854 4.646C8.80751 4.59951 8.75232 4.56264 8.69158 4.53748C8.63084 4.51232 8.56574 4.49937 8.5 4.49937C8.43426 4.49937 8.36916 4.51232 8.30842 4.53748C8.24768 4.56264 8.19249 4.59951 8.146 4.646C8.09951 4.69249 8.06264 4.74768 8.03748 4.80842C8.01232 4.86916 7.99937 4.93426 7.99937 5C7.99937 5.06574 8.01232 5.13084 8.03748 5.19158C8.06264 5.25232 8.09951 5.30751 8.146 5.354L10.293 7.5H4.5Z"
            fill="#FDFDFD"
          />
        </svg>
      </a>
    </Container>
  );
}
